<template>
  <div class="class-wrap">
    <a-spin :spinning="loading" size="large">
      <a-table :columns="columns" :data-source="classDetail" :pagination="false" style="margin-bottom: 20px">
        <span slot="classType" slot-scope="text">{{ getDdlLabel(scheduleOptions.classType, text) }}</span>
        <span slot="courseType" slot-scope="text">{{ getDdlLabel(scheduleOptions.courseType, text) }}</span>
        <span slot="timezone" slot-scope="text">{{ getTimezoneName(text) }}</span>
        <span slot="platform" slot-scope="platform, record">
          <span
            v-if="platform === 'CLASS_IN'"
            style="color: #3372fe; cursor: pointer"
            @click="toClassIn(record.classInId)"
          >
            {{ `ClassIn(${record.classInId})` }}
          </span>
          <span v-if="platform === 'TALK_CLOUD'"> 悟空教室 </span>
        </span>

        <span slot="action" slot-scope="text, record">
          <a @click="onEditClass(record)">编辑</a>
          <a @click="updatePlatformFun(record)" style="margin-left: 5px" v-if="showPlatFlagHandle(record)">更换平台</a>
        </span>
      </a-table>

      <a-tabs v-model="activeKey" @change="onTabChange">
        <a-tab-pane key="1" tab="排课列表">
          <a-row :gutter="24">
            <a-form-model
              :model="scheduleForm"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              class="ant-advanced-search-form"
            >
              <a-col :span="6">
                <a-form-model-item label="课节名称">
                  <a-input v-model="scheduleForm.scheduleName" @pressEnter="handleSearchData()" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="课节状态">
                  <a-select v-model="scheduleForm.scheduleStatuses" placeholder="请选择课节状态" mode="multiple">
                    <a-select-option
                      v-for="item in scheduleOptions.scheduleStatus"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="星 期">
                  <a-select v-model="scheduleForm.dayOfWeeks" placeholder="请选择星期" mode="multiple">
                    <a-select-option v-for="item in dayOfWeekOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="授课老师">
                  <a-select v-model="scheduleForm.teacherId" placeholder="请选择授课老师" allowClear>
                    <a-select-option
                      v-for="item in (classDetail[0] && classDetail[0].teachers) || []"
                      :key="item.uuid"
                      :value="item.uuid"
                    >
                      {{ item.fullName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item>
                  <a-button @click="handleSearchData()" style="margin-right: 10px"> 查询 </a-button>
                  <span v-if="classDetail[0] && classDetail[0].authorized && classDetail[0].classStatus === 'NORMAL'">
                    <a-button type="primary" @click="toSchedule()" style="margin-right: 10px"> 排课 </a-button>
                    <a-button
                      type="primary"
                      @click="showCancelScheduleModal()"
                      style="margin-right: 10px"
                      :disabled="!hasSelected"
                      :loading="cancelLoading"
                    >
                      批量取消
                    </a-button>
                    <a-button
                      type="primary"
                      @click="showBatchUpdateScheduleModal()"
                      style="margin-right: 10px"
                      :disabled="!hasSelected"
                    >
                      批量修改助教
                    </a-button>
                    <!-- <a-button
                      type="primary"
                      @click="showShiftSectionModal()"
                      style="margin-right: 10px"
                      :disabled="!hasSelected"
                    >
                      调整时间
                    </a-button> -->
                    <a-button
                      type="primary"
                      @click="showBatchUpdateTeacherModal()"
                      style="margin-right: 10px"
                      :disabled="!hasSelected"
                      :loading="batchUpdateTeacherLoading"
                    >
                      修改授课老师
                    </a-button>
                    <a-button
                      type="primary"
                      @click="showAdjustWeekFun()"
                      v-if="classDetail[0].classType === 'PRIVATE' && classDetail[0].course.courseType === 'STANDARD'"
                      style="margin-right: 10px"
                      :loading="adjustWeekloading"
                    >
                      {{ hasSelected ? '调整选中待开课星期' : '调整全部待开课节星期' }}
                    </a-button>
                  </span>
                </a-form-model-item>
              </a-col>
            </a-form-model>
          </a-row>
          <a-table
            :columns="scheduleColumns"
            :data-source="pagination.content"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
            :row-selection="{
              onSelect: onSelect,
              onSelectAll: onSelectAll,
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :rowKey="(record) => record.uuid"
          >
            <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              style="padding: 8px"
            >
              <a-select
                v-ant-ref="(c) => (searchInput = c)"
                show-search
                placeholder="所在时区"
                option-filter-prop="children"
                :filter-option="filterOption"
                v-model="scheduleForm.convertTimezone"
                style="width: 220px; margin-bottom: 8px; display: block"
              >
                <a-select-option :value="i.value" v-for="(i, index) of timezones" :key="index">
                  {{ i.label }}
                </a-select-option>
              </a-select>
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 40px"
                @click="() => handleSearchTimezone(selectedKeys, confirm, column.dataIndex)"
              >
                确定
              </a-button>
              <a-button size="small" style="width: 90px" @click="() => handleTimezone(clearFilters)"> 重置</a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="swap"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <span slot="scheduleName" slot-scope="text, record">
              <span style="color: red" v-if="record.roomInitStatus === 'ERROR'">同步classIn失败,请尽快联系教务～</span>
              <a-tooltip placement="topLeft" :key="record.uuid">
                <template slot="title">
                  {{ text }}
                  <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
                </template>
                <router-link :to="{ path: '/workoutSchedule/scheduleDetailSub', query: { id: record.uuid } }">
                  <a-tag color="red" v-if="record.classType === 'PRIVATE' && record.data.fromLeaveId">补</a-tag>
                  <span>{{ text }}</span>
                  <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
                </router-link>
              </a-tooltip>
            </span>
            <span slot="dayOfWeek" slot-scope="text">{{ getDdlLabel(adjustWeekOptions, text) }}</span>

            <span slot="scheduleStatus" slot-scope="text">{{
              getDdlLabel(scheduleOptions.scheduleStatus, text) || ''
            }}</span>
            <span slot="cancelReason" slot-scope="text, record">
              {{ getDdlLabel(scheduleOptions.scheduleCancelReasonAll, text) || '' }}
              {{
                record.data &&
                record.data.cancelReason === 'OTHER' &&
                record.data.cancelReasonData &&
                record.data.cancelReasonData.otherCancelReason
                  ? ': ' + record.data.cancelReasonData.otherCancelReason
                  : ''
              }}
            </span>
            <span slot="startDateTime" slot-scope="startDateTime, record">
              {{
                `${startDateTime}${getDdlLabel(timezoneList, record.convertTimezone).substring(
                  0,
                  getDdlLabel(timezoneList, record.convertTimezone).indexOf(')') + 1,
                )}`
              }}
            </span>
            <span slot="teacher" slot-scope="text, record">
              <a-popover placement="bottom">
                <template slot="content">
                  <teacher-info
                    :key="record.teacher && record.teacher.uuid"
                    :teacherId="record.teacher && record.teacher.uuid"
                    :subjectType="
                      record.courseSectionRespDTO &&
                      record.courseSectionRespDTO.data &&
                      record.courseSectionRespDTO.data.subjectType
                    "
                  ></teacher-info>
                </template>
                <span style="cursor: pointer">
                  {{ text }}
                  <span v-if="record.data && record.data.substitute" style="white-space: nowrap; color: red"
                    >(代课)</span
                  >
                </span>
              </a-popover>
            </span>
            <div slot="action" slot-scope="text, record" style="word-break: keep-all">
              <template v-if="record.scheduleStatus === 'NORMAL' && classDetail[0] && classDetail[0].authorized">
                <a-button type="link" style="padding: 4px" @click="showUpdateScheduleModal(text, record)">
                  编辑
                </a-button>
                <a-button type="link" style="padding: 4px" @click="showAdjustSectionModal(text, record)">
                  调级
                </a-button>
                <a-button
                  :loading="cancelLoading"
                  type="link"
                  style="padding: 4px"
                  @click="showCancelScheduleModal(text, record)"
                >
                  取消
                </a-button>
              </template>
              <a-button
                type="link"
                style="padding: 4px"
                @click="playCourse(record)"
                v-if="record.scheduleStatus === 'COMPLETED'"
                >回放</a-button
              >
              <a-button
                type="link"
                style="padding: 4px"
                @click="playClass(record)"
                v-if="record.scheduleStatus === 'NORMAL'"
                >监课</a-button
              >
              <a-button
                type="link"
                style="padding: 4px"
                @click="adjustTime(record)"
                v-if="
                  record.classType === 'PRIVATE' &&
                  record.courseSectionRespDTO.course.courseType === 'STANDARD' &&
                  record.scheduleStatus === 'NORMAL' &&
                  classDetail[0] &&
                  classDetail[0].authorized
                "
                :loading="adjustTimeloading"
              >
                调时间
              </a-button>
            </div>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="学生列表" force-render>
          <a-row :gutter="24">
            <a-form-model
              :model="studentForm"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              class="ant-advanced-search-form"
            >
              <a-col :span="6">
                <a-form-model-item label="学生姓名">
                  <a-input v-model="studentForm.studentName" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="学 号">
                  <a-input v-model="studentForm.code" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item>
                  <a-button @click="renderStudentListTab()" style="margin-right: 10px"> 查询 </a-button>
                  <a-button
                    type="primary"
                    @click="showAddStudentModal()"
                    v-if="classDetail[0] && classDetail[0].classStatus === 'NORMAL'"
                  >
                    添加学生
                  </a-button>
                </a-form-model-item>
              </a-col>
            </a-form-model>
          </a-row>
          <a-table
            :columns="studentColumns"
            :data-source="pagination.content"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <!-- <span slot="scheduleStatus" slot-scope="text">{{ getDdlLabel(scheduleOptions.scheduleStatus, text) }}</span>
          <span slot="cancelReason" slot-scope="text">{{
            getDdlLabel(scheduleOptions.scheduleCancelReasonAll, text)
          }}</span> -->
            <span slot="studentName" slot-scope="text, record">
              <a @click="toStudentDetail(record)">{{ text }}</a>
            </span>
            <span slot="studentStatus" slot-scope="status, record">
              <span v-if="classDetail[0] && classDetail[0].course && classDetail[0].course.subject === 'CHINESE'">{{
                record.student && record.student.data && record.student.data.status
              }}</span>
              <span v-if="classDetail[0] && classDetail[0].course && classDetail[0].course.subject === 'MATH'">{{
                record.student && record.student.data && record.student.data.mathStatus
              }}</span>
            </span>
            <span slot="action" slot-scope="text, record">
              <ModalForm title="挪出学生" :onFinish="({ reason }) => handleRemoveStudent(reason, record)">
                <a slot="trigger">
                  <!-- @click="deleteStudent(text, record)" -->
                  挪出
                </a>
                <template slot="form">
                  <a-form-item label="挪出原因">
                    <a-select
                      placeholder="请选择"
                      :options="removeStudentReasonList"
                      v-decorator="['reason', { rules: [{ required: true, message: '请选择挪出原因' }] }]"
                    />
                  </a-form-item>
                </template>
              </ModalForm>
              <a @click="selfReschedule(record)">自主改约链接</a>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>

      <a-modal v-model="updateScheduleVisible" title="修改课节" @ok="updateSchedule">
        <a-form-model
          ref="ruleForm"
          :model="updateScheduleForm"
          :rules="updateScheduleRules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-model-item ref="scheduleName" label="课节名称" prop="scheduleName" required>
            <a-input v-model="updateScheduleForm.scheduleName" />
          </a-form-model-item>
          <a-form-model-item label="助教老师" prop="assistant">
            <a-select
              v-model="updateScheduleForm.assistant"
              option-filter-prop="children"
              :filter-option="filterOption"
              show-search
              @search="queryAssistantOptions"
              placeholder="请选择助教老师"
            >
              <a-select-option v-for="item in assistantOptions" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="seatNum" label="上台人数" prop="seatNum" placeholder="上台人数(1-12数字)" required>
            <a-input-number
              :min="1"
              :max="12"
              v-model="updateScheduleForm.seatNum"
              style="width: 100%"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <a-modal v-model="adjustSectionVisible" title="调整课节级别" @ok="adjustSection" :zIndex="998">
        <a-form-model ref="ruleForm" :model="updateScheduleForm" :rules="updateScheduleRules" layout="horizontal">
          <label
            >将原级别 <b>{{ adjustSectionForm.sectionName }}</b> 调整为：</label
          >
          <a-form-model-item label="新级别">
            <a-select
              v-model="adjustSectionForm.sectionId"
              placeholder="请选择新级别"
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
            >
              <a-select-option v-for="item in sectionOptions" :key="item.uuid" :value="item.uuid">
                {{ item.sectionName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-checkbox v-model="adjustSectionForm.isBatchAdjust"> 是否将之后的所有课节 <b>按新级别重排</b>? </a-checkbox>
        </a-form-model>
      </a-modal>

      <a-modal v-model="cancelScheduleVisible" title="取消排课" @ok="cancelSchedule">
        <a-form-model>
          <label
            >确定要取消 <b style="color: red">{{ cancelScheduleName }}</b> 吗？</label
          >
          <a-select v-model="scheduleCancelReason" placeholder="请选择取消原因" style="margin: 10px 0">
            <a-select-option v-for="item in scheduleOptions.scheduleCancelReason" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-textarea
            v-model="otherCancelReason"
            placeholder="请输入其他原因"
            v-if="scheduleCancelReason === 'OTHER'"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
          <span style="color: red" v-if="!scheduleCancelReason">请选择取消原因</span>
          <span style="color: red" v-if="scheduleCancelReason === 'OTHER' && !otherCancelReason.trim()"
            >请输入其他原因</span
          >
          <div>
            <a-checkbox v-model="realignChecked">{{ realignCheckBoxText }}</a-checkbox>
          </div>
        </a-form-model>
      </a-modal>

      <a-modal v-model="batchUpdateScheduleVisible" title="批量修改助教" @ok="batchUpdateSchedule">
        <a-form-model>
          <a-form-model-item label="助教老师" prop="assistant">
            <a-select
              v-model="batchAssistantId"
              placeholder="请选择助教老师"
              option-filter-prop="children"
              :filter-option="filterOption"
              show-search
              @search="queryAssistantOptions"
            >
              <a-select-option v-for="item in assistantOptions" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <span style="color: red" v-if="!batchAssistantId">请选择助教老师</span>
        </a-form-model>
      </a-modal>
      <a-modal
        v-model="batchUpdateTeacherVisible"
        title="修改授课老师"
        @cancel="onCloseTeacher"
        @ok="batchUpdateTeacher"
        :zIndex="998"
        width="600px"
        :okButtonProps="{
          props: {
            loading: teacherLoading,
          },
        }"
      >
        <a-form :form="teacherForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="新授课老师">
            <a-select
              showSearch
              placeholder="请选择新授课老师"
              option-filter-prop="children"
              :filter-option="filterOption"
              @search="searchAssignedTeacher"
              v-decorator="['batchTeacherId', { rules: [{ required: true, message: '请选择新授课老师!' }] }]"
            >
              <a-select-option v-for="item in assignedTeacherOptions" :key="item.uuid" :value="JSON.stringify(item)">
                {{ item.fullName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- <span style="color: red" v-if="!batchTeacherId">请选择新授课老师</span> -->

          <a-form-item label="是否代课">
            <a-radio-group
              @change="changeSubstitute"
              v-decorator="['substitute', { rules: [{ required: true, message: '请选择是否代课!' }] }]"
            >
              <a-radio :value="true"> 临时代课 </a-radio>
              <a-radio :value="false"> 长期交接(将之后课节都更换为此老师) </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="原因" v-if="!showSubstitute">
            <a-select
              placeholder="请选择"
              v-decorator="['reason', { rules: [{ required: true, message: '请选择原因!' }] }]"
            >
              <a-select-option v-for="(item, index) in reasonOptions" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="备注" v-if="!showSubstitute">
            <a-textarea v-decorator="['remark']" placeholder="请输入" :auto-size="{ minRows: 1 }" />
          </a-form-item>
          <!-- <div>
            <a-checkbox v-model="batchTeacher" @change="onChangeTeacher">是否将之后的所有课节都换为此老师</a-checkbox>
          </div> -->
        </a-form>
      </a-modal>
      <a-modal v-model="shiftSectionVisible" title="调整课节时间" @ok="shiftSection">
        <a-form-model>
          <label>将原课节调整为：</label>
          <a-form-model-item label="新时间">
            <a-select v-model="newSectionTime" placeholder="请选择时间间隔" show-search>
              <a-select-option v-for="item in sectionTimeOptions" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-checkbox v-model="isBatchShift"> 是否将之后的所有课节 <b>按新时间间隔重排</b>? </a-checkbox>
        </a-form-model>
      </a-modal>
      <a-modal v-model="addStudentVisible" title="添加学生" @ok="addStudent">
        <a-form-model>
          <a-form-model-item>
            <a-select
              @change="selectStudentInfo"
              v-model="studentId"
              placeholder="请选择学生(支持学号，姓名搜索)"
              show-search
              @search="fetchStudent"
              :filter-option="false"
              :dropdown-match-select-width="false"
              option-label-prop="label"
              :not-found-content="null"
              allow-clear
            >
              <a-select-option
                v-for="item in studentOptions"
                :key="item.id"
                :value="item.id"
                :data="item"
                :label="`${item.name}(${item.code})`"
              >
                <div style="display: flex">
                  <div style="width: 200px; overflow: hidden; text-overflow: ellipsis">
                    <b>{{ item.name }}</b>
                  </div>
                  <div style="width: 100px">{{ item.code }}</div>
                  <div style="width: 150px">生 日：{{ item.dateOfBirth || '未知' }}</div>
                  <div style="width: 150px; color: red">
                    中文课时：{{ (item.cuDetail && item.cuDetail.chineseRemainingCu) || 0 }}
                  </div>
                  <div style="width: 150px; color: red">
                    数学课时：{{ (item.cuDetail && item.cuDetail.mathRemainingCu) || 0 }}
                  </div>
                  <div style="width: 150px; color: red">
                    英文课时：{{ (item.cuDetail && item.cuDetail.englishRemainingCu) || 0 }}
                  </div>
                </div>
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-date-picker
              :disabled-date="disabledDate"
              v-model="startDateTime"
              placeholder="开始日期"
              style="width: 100%"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-spin>

    <a-modal
      :okButtonProps="{
        props: {
          loading: iconLoading,
        },
      }"
      v-model="showAdjustFlag"
      title="调整课节时间"
      @cancel="onCloseAdjust"
      @ok="adjustTimeFun"
    >
      <a-form :form="adjustForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <div style="margin-bottom: 15px">
          将<span style="color: rgb(51, 114, 254)">
            {{ tableListItem.scheduleName }}（{{ tableListItem.startDateTime
            }}{{ getDdlLabel(dayOfWeekOptions, tableListItem.scheduleDayOfWeek) }}）</span
          >时间调整为：
        </div>
        <a-form-item label="选择新时间">
          <a-date-picker
            :inputReadOnly="true"
            show-time
            placeholder="请选择"
            :showTime="{ format: 'HH:mm', minuteStep: minuteStep }"
            format="YYYY-MM-DD HH:mm"
            v-decorator="['date', { rules: [{ required: true, message: '请选择新时间!' }] }]"
            style="width: 100%"
          />
        </a-form-item>
        <div style="margin-top: 10px">注意：此处时间为班级时区</div>
      </a-form>
    </a-modal>
    <a-modal
      v-model="showAdjustWeek"
      title="调整课节星期"
      :okButtonProps="{
        props: {
          loading: iconLoading,
        },
      }"
      @cancel="onCloseWeek"
      @ok="adjustWeekFun"
    >
      <a-form :form="weekForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 10 }">
        <div v-for="(item, key, index) in adjustWeekObj" :key="key" style="margin-bottom: 20px">
          <div style="font-size: 14px; font-weight: bold">{{ initOptionsType(key, adjustWeekOptions) }} {{ item }}</div>
          <div class="week-wrap">
            <a-form-item label="新课节星期">
              <a-select placeholder="请选择课节星期" v-decorator="[`week${key}`]" allowClear>
                <a-select-option v-for="item in adjustWeekOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="新课节时分">
              <a-time-picker
                style="width: 100%"
                v-decorator="[`time${key}`]"
                :default-open-value="$moment('00:00:00', 'HH:mm')"
                :minute-step="minuteStep"
                :inputReadOnly="true"
                format="HH:mm"
              />
            </a-form-item>
          </div>
        </div>

        <div style="margin-top: 10px">注意：此处时间为班级时区</div>
      </a-form>
    </a-modal>
    <a-modal v-model="isVisible" :title="error.message" @ok="handleOk">
      <p v-for="item in error.data.list" :key="item">{{ item }}</p>
    </a-modal>
    <a-modal
      v-model="isCheckClassVisible"
      title="提示"
      okText="继续排课"
      :zIndex="999"
      :okButtonProps="{
        props: {
          loading: continueClassLoading,
        },
      }"
      @ok="continueClass"
    >
      <p>
        注意：本次操作影响课节授课老师中不包含“<span v-for="(item, index) in structuresList" :key="index"
          >{{ item.structure }}<span v-if="structuresList.length !== index + 1">、</span></span
        >”级别
      </p>
      <p style="color: #868585">可取消本次排课，也可继续排课</p>
    </a-modal>
    <a-modal
      v-model="isEndClassVisible"
      title="提示"
      okText="我知道了"
      cancelText="取消"
      :footer="null"
      @ok="cancelEndClass"
      @cancel="cancelEndClass"
    >
      <div>
        该学员<span style="color: #04cb94">{{ `${addStudentName}(${addStudentCode})` }}</span
        >在该班级中的第一节课为（班级时区时间）：{{ firstCourseSchedule.startDateTime }}（{{
          firstCourseSchedule.courseScheduleName
        }}）
      </div>
    </a-modal>
    <a-modal
      v-model="isBillVisible"
      title="提示"
      okText="指定发单"
      :zIndex="1000"
      cancelText="取消"
      @ok="hrefBillClass"
    >
      <p>本次操作包含<span style="color: red">24小时内课节</span>，不可直接操作！</p>
      <p>
        可
        <span style="color: #04cb94">指定老师发单（{{ selectedTeacherName }}）</span
        >，发单中仅包含本次调整中的24小时内课节
      </p>
    </a-modal>
    <a-modal v-model="isWeekVisible" title="提示" :footer="null">
      <p>本次操作包含<span style="color: red">24小时内课节</span>，不可直接操作！</p>
      <p>可点击24小时内课节点击 <span style="color: #04cb94">”调时间“</span>进行指定老师发单</p>
    </a-modal>
    <a-modal
      v-model="updatePlatformVisible"
      title="更换平台"
      @cancel="onClosePlatform"
      @ok="batchPlatform"
      width="600px"
      :okButtonProps="{
        props: {
          loading: platformLoading,
        },
      }"
    >
      <a-form :form="platformForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="班级上课平台">
          <a-select
            placeholder="请选择班级上课平台"
            v-decorator="['newPlatForm', { rules: [{ required: true, message: '请选择班级上课平台!' }] }]"
          >
            <a-select-option v-for="item in platformsArr" :key="item.uuid" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <div style="margin: 16px 0 0 16px">
        注意：班级切换上课平台后，该班级所有<span style="font-weight: bold">待开课课节即在该平台上课！</span>
      </div>
    </a-modal>
  </div>
</template>
<script>
import debounce from 'lodash.debounce';
import storage from 'store';
import {
  postReq,
  getReq,
  putReq,
  deleteReq,
  loadScheduleOptions,
  loadTimezoneList,
  checkTeacherClass,
  checkCourseSchedule,
  checkAdjustDateSchedule,
  checkAdjustWeekSchedule,
  queryPlayCourse,
  validateSelfRescheduleLink,
} from '@/api/schedule';
import { dayOfWeekOptions, sectionTimeOptions } from '@/utils/const';
import { getDdlLabel } from '@/utils/util';
import { timezones } from '@/api/headTeacher';
import TeacherInfo from '@/views/schedule/teacherInfo';
import { getReplayLink } from '@/api/common';
import { getTimezoneName } from '@/utils/geoLocation';
import { autoCancelScheduleOrderModal } from '@/utils/autoCancelScheduleOrderModal.js';
import ModalForm from '@/components/ModalForm';
import queryString from 'query-string';
import { handleCopy } from '@/utils/util';
import { shortLinkByLong } from '@/api/common';
import { reportEvent } from '@/utils/gatherer';

const roles = storage.get('roles');

const columns = [
  {
    title: '班级名称',
    dataIndex: 'className',
    key: 'className',
    width: 180,
    ellipsis: true,
  },
  {
    title: '班型',
    dataIndex: 'classType',
    key: 'classType',
    scopedSlots: { customRender: 'classType' },
    width: 85,
  },
  {
    title: '学生容量',
    key: 'capacity',
    dataIndex: 'capacity',
    width: 85,
  },
  {
    title: '班级时区',
    dataIndex: 'timezone',
    key: 'timezone',
    scopedSlots: { customRender: 'timezone' },
  },
  {
    title: '课程名称',
    key: 'course.courseName',
    dataIndex: 'course.courseName',
    width: 140,
    ellipsis: true,
  },
  {
    title: '课程类型',
    key: 'course.courseType',
    dataIndex: 'course.courseType',
    scopedSlots: { customRender: 'courseType' },
    width: 85,
  },
  {
    title: '开课级别',
    key: 'beginSectionName',
    dataIndex: 'beginSectionName',
  },
  {
    title: '当前级别',
    key: 'currentSectionName',
    dataIndex: 'currentSectionName',
  },
  {
    title: '课程进度',
    key: 'progress',
    dataIndex: 'progress',
    width: 85,
  },

  {
    title: '班主任',
    key: 'data.headmasterName',
    dataIndex: 'data.headmasterName',
  },
  {
    title: '学生数量',
    key: 'studentCount',
    dataIndex: 'studentCount',
    width: 85,
  },
  {
    title: '上课平台',
    key: 'platform',
    dataIndex: 'platform',
    scopedSlots: { customRender: 'platform' },
  },

  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 120,
  },
];
const scheduleColumns = [
  {
    title: '课节名称',
    dataIndex: 'scheduleName',
    key: 'scheduleName',
    scopedSlots: { customRender: 'scheduleName' },
    width: 180,
    ellipsis: true,
  },
  {
    title: '级别',
    dataIndex: 'courseSectionRespDTO.structure',
    key: 'courseSectionRespDTO.structure',
    width: 180,
  },
  {
    title: '星期',
    key: 'dayOfWeek',
    dataIndex: 'dayOfWeek',

    scopedSlots: {
      customRender: 'dayOfWeek',
    },
  },
  {
    title: '开课时间',
    dataIndex: 'startDateTime',
    key: 'startDateTime',
    width: 150,

    scopedSlots: {
      customRender: 'startDateTime',
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
    onFilter: (value, record) => record.address.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
  },
  {
    title: '课节时长',
    key: 'duration',
    dataIndex: 'duration',

    customRender: (text, record, index) => `${text}m`,
  },
  {
    title: '授课老师',
    key: 'teacher.fullName',
    dataIndex: 'teacher.fullName',

    scopedSlots: { customRender: 'teacher' },
  },
  {
    title: '助教老师',
    key: 'data.assistantName',
    dataIndex: 'data.assistantName',
  },
  {
    title: '插班生',
    key: 'transferStudents',
    dataIndex: 'transferStudents',
  },
  {
    title: '调出生',
    key: 'moveoutStudents',
    dataIndex: 'moveoutStudents',

    customRender: (text, record, index) => `${text && text.join(', ')}`,
  },
  {
    title: '状态',
    key: 'scheduleStatus',
    dataIndex: 'scheduleStatus',

    scopedSlots: { customRender: 'scheduleStatus' },
  },
  {
    title: '状态原因',
    key: 'data.cancelReason',
    dataIndex: 'data.cancelReason',

    scopedSlots: { customRender: 'cancelReason' },
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

const studentColumns = [
  {
    title: '学生姓名',
    dataIndex: 'student.fullName',
    key: 'student.fullName',
    scopedSlots: { customRender: 'studentName' },
  },
  {
    title: '学号',
    dataIndex: 'student.code',
    key: 'student.code',
  },
  {
    title: '生日',
    key: 'student.dateOfBirth',
    dataIndex: 'student.dateOfBirth',
  },
  {
    title: '国家/地区',
    dataIndex: 'student.country',
    key: 'student.country',
  },
  {
    title: '开始时间',
    key: 'data.studentStartDateTimeFormat',
    dataIndex: 'data.studentStartDateTimeFormat',
  },
  {
    title: '付费状态',
    key: 'student.data.status',
    dataIndex: 'student.data.status',
    scopedSlots: { customRender: 'studentStatus' },
  },
  {
    title: '学生状态',
    key: 'stuFormClassStatus',
    dataIndex: 'stuFormClassStatus',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  name: 'workoutClassDetail',
  components: {
    TeacherInfo,
    ModalForm,
  },
  data() {
    this.fetchStudent = debounce(this.fetchStudent, 500);
    return {
      removeStudentReasonList: [
        '更换上课时间',
        '调整系列/级别',
        '选择其他老师',
        '转私教课',
        '冻结停课',
        '延期开课',
        '退费',
        '不续费',
        '课时转移',
        '机构原因调整',
      ].map((item) => ({ label: item, value: item })),
      getTimezoneName,
      platformsArr: [
        { label: 'Classin', value: 'CLASS_IN' },
        { label: '悟空教室', value: 'TALK_CLOUD' },
      ],
      userInfo: {},
      showPlatFlag: false,
      oldPlatForm: '',
      platformLoading: false,
      updatePlatformVisible: false,
      continueClassType: '',
      courseScheduleIds: [], // 创建指定紧急单
      isWeekVisible: false,
      schedulingSource: '',
      sectionTimes: [],
      sectionIds: [],
      selectedTeacherName: null,
      selectedTeacherId: null,
      selectedTeacherCode: null,
      isBillVisible: false,
      platform: '',
      continueClassType: '',
      addStudentName: '',
      addStudentCode: '',
      firstCourseSchedule: {},
      teacherLoading: false,
      isEndClassVisible: false,
      continueClassLoading: false,
      isCheckClassVisible: false,
      structuresList: [],
      iconLoading: false,
      showSubstitute: true,
      reasonOptions: [
        '老师请假',
        '老师拒课',
        '老师离职',
        '老师交接班',
        '班主任排错课',
        '教务排错课',
        '家长对授课老师不满意',
        '家长对助教老师不满意',
        '家长要求更换上课时间',
        '学习规划师取消体验课',
        '班级结课',
        'classin系统故障',
        '其他',
      ],
      minuteStep: roles.includes('TEACHING_STAFF') ? 1 : 20,
      timezones: null,
      searchInput: null,
      adjustWeekObj: {},
      adjustWeekOptions: [
        { label: '周一', value: 'MONDAY' },
        { label: '周二', value: 'TUESDAY' },
        { label: '周三', value: 'WEDNESDAY' },
        { label: '周四', value: 'THURSDAY' },
        { label: '周五', value: 'FRIDAY' },
        { label: '周六', value: 'SATURDAY' },
        { label: '周日', value: 'SUNDAY' },
      ],
      showAdjustWeek: false,
      tableListItem: {},
      showAdjustFlag: false,
      error: {
        data: {
          list: [],
        },
        message: null,
      },
      isVisible: false,
      teacherForm: this.$form.createForm(this),
      adjustForm: this.$form.createForm(this),
      weekForm: this.$form.createForm(this),
      platformForm: this.$form.createForm(this),
      formLayout: 'horizontal',
      batchTeacher: false,
      batchTeacherId: null,
      assignedTeacherOptions: [],
      batchUpdateTeacherVisible: false,
      dayOfWeekOptions,
      sectionTimeOptions,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      getDdlLabel,
      data: [],
      classId: this.$route.query.classId,
      classDetail: [],
      columns,
      scheduleColumns,
      studentColumns,
      loading: false,
      pagination: {
        pageSize: 20,
        current: 1,
        total: 0,
        content: [],
      },
      searchUrl: `/api/admin/scheduling/course_schedule/search/`,
      searchStudentUrl: `/api/admin/scheduling/form_class/student/search/`,
      scheduleForm: {
        scheduleName: null,
        dayOfWeeks: [],
        scheduleStatuses: ['NORMAL'],
        teacherId: null,
        formClassId: this.$route.query.classId,
        lessData: true,
        convertTimezone: undefined,
        pageSize: 20,
      },
      studentForm: {
        studentName: null,
        code: null,
        formClassId: this.$route.query.classId,
      },
      curScheduleId: null,
      updateScheduleForm: {
        // 编辑课节Modal
        scheduleName: null,
        assistant: null,
        seatNum: null,
      },
      updateScheduleRules: {
        // 编辑课节Modal表单验证规则
        scheduleName: [{ required: true, message: '请输入课节名称', trigger: 'change' }],
        // assistant: [{ required: true, message: '请选择助教', trigger: 'change' }],
        seatNum: [
          { required: true, message: '请输入上台人数(1-12整数)', trigger: 'change', type: 'integer', min: 1, max: 12 },
        ],
      },
      // 调级Modal表单
      adjustSectionForm: {
        sectionName: null,
        sectionId: null,
        isBatchAdjust: false,
      },
      courseId: null,
      scheduleOptions: {
        classType: [],
        courseType: [],
        scheduleOptions: [],
        scheduleCancelReason: [],
      },
      assistantOptions: [],
      sectionOptions: [],
      studentOptions: [],
      timezoneList: [],
      activeKey: '1', // 默认展示第一个tab
      // 取消课节
      scheduleCancelReason: null,
      cancelScheduleName: null,
      otherCancelReason: '',
      selectedIds: [],

      updateScheduleVisible: false,
      batchUpdateScheduleVisible: false,
      adjustSectionVisible: false,
      cancelScheduleVisible: false,
      shiftSectionVisible: false,
      addStudentVisible: false,

      batchAssistantId: null, // 批量修改助教
      newSectionTime: null, // 批量调节时间
      isBatchShift: false,

      studentId: undefined, // 添加学生
      startDateTime: null,

      selectedRowKeys: [],
      selectedRows: [],
      batchUpdateTeacherLoading: false,
      cancelLoading: false,
      adjustWeekloading: false,
      realignChecked: false,
      realignFirstSchedule: undefined,
      adjustTimeloading: false,
    };
  },
  created() {
    this.classId = this.$route.query.classId;
    this.userInfo = storage.get('userInfo');
    // 暂时开放这两个账号可以用
    if (
      [
        // 测试
        '8ec0845a-107a-43d9-b9b4-abd405492891',
        '7f78b7f7-2c30-49ee-9dd2-8ae5dd711169',
        // 线上
        '883392c9-54a0-4fb2-81ea-099d0cff7169',
        '7b7c32c2-8218-4262-a0c1-093147d43527',
        'ee2096e5-c867-47a8-9caf-e8bacaa8df50',
        'bbfc606d-eebd-4f61-935c-1c0dec375af3',
      ].includes(this.userInfo.userID)
    ) {
      this.showPlatFlag = true;
    } else {
      this.showPlatFlag = false;
    }
    // 获取时区列表
    timezones().then((i) => {
      this.timezones = i.data.content;
    });
    this.onTabChange(); // 默认加载排课Tab
    this.getClassDetail();
    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });
    loadTimezoneList((res) => {
      this.timezoneList = res;
    });
    this.renderScheduleListTab();
  },
  activated() {
    // this.activeKey='1';
    // this.classId = this.$route.query.classId;
    // this.getClassDetail();
    // this.renderScheduleListTab();
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
    realignCheckBoxText() {
      console.log(this.realignFirstSchedule);

      return `将之后的所有课节从${this.realignFirstSchedule?.data?.sectionName}重排`;
    },
  },
  methods: {
    async selfReschedule(record) {
      const { data: result } = await validateSelfRescheduleLink({
        formClassId: this.classId,
        studentId: record.student.uuid,
      });

      if (!result) {
        this.$message.error('没有待开课的标准课课节，无法复制链接');
        return;
      }

      const baseUrl = process.env.VUE_APP_STUDENT_CENTER_URL;

      const rescheduleQueryString = queryString.stringify({
        formClassId: this.classId,
        appTag: '1',
      });

      const webUrl = baseUrl + '/home/self-service-rescheduling?' + rescheduleQueryString;
      const mobileUrl = baseUrl + '/m/self-service-rescheduling?' + rescheduleQueryString;

      const jumpQueryString = queryString.stringify({
        studentId: record.student.uuid,
        failBack: 'store',
        webLink: webUrl,
        tabletLink: mobileUrl,
        webviewLink: mobileUrl,
        subject: this.classDetail[0].course.subject,
      });

      const jumpUrl = baseUrl + '/r?' + jumpQueryString;
      const { data: shortUrl } = await shortLinkByLong(jumpUrl);
      await handleCopy(shortUrl);

      this.$message.success('复制成功');
      reportEvent({
        data: {
          _event: 'CAstudio_CopyRescheduleLink',
          Course_id: this.courseId,
          Student_id: record.student.uuid,
          Copelink: shortUrl,
        },
      });
    },
    async handleRemoveStudent(reason, item) {
      await new Promise((resolve) => {
        this.$confirmAnt({
          title: '删除学生',
          content: `挪出学生后，系统将同步把学生移除所有课节，你确定要挪出学生吗？`,
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            this.loading = true;
            await deleteReq(
              `/api/admin/scheduling/form_class/student/${this.classId}/${item.student.uuid}?reason=${reason}`,
              {},
            )
              .then(() => {
                this.renderStudentListTab();
                this.getClassDetail();
              })
              .finally(() => {
                this.loading = false;
              });
            resolve();
          },
          onCancel: () => resolve(),
        });
      });
      return true;
    },
    showPlatFlagHandle(data) {
      return data.classType === 'PRIVATE' && !this.userInfo.roleArr.includes('CLASS_ADMIN');
    },
    batchPlatform() {
      this.platformForm.validateFields((err, values) => {
        if (!err) {
          this.platformLoading = true;
          // 更换上课平台
          postReq('/api/admin/scheduling/form_class/transfer/platform', {
            formClassId: this.classId,
            newPlatForm: values.newPlatForm,
            oldPlatForm: this.oldPlatForm,
          })
            .then((res) => {
              this.platformLoading = false;
              this.onClosePlatform();
              this.getClassDetail();
              this.renderScheduleListTab();
            })
            .catch((err) => {
              this.platformLoading = false;
            });
        }
      });
    },
    updatePlatformFun(record) {
      this.oldPlatForm = record.platform;
      this.updatePlatformVisible = true;
      this.platformForm.setFieldsValue({
        newPlatForm: record.platform,
      });
    },
    onClosePlatform() {
      this.updatePlatformVisible = false;
    },
    // 指定老师发单
    hrefBillClass() {
      const classItem = {
        courseId: this.courseId,
        formClassName: this.classDetail[0].className,
        classType: this.classDetail[0].classType,
        formClassId: this.classId,
        courseType: this.classDetail[0].course.courseType,
        courseName: this.classDetail[0].course.courseName,
        teacherName: this.selectedTeacherName,
        teacherId: this.selectedTeacherId,
        teacherCode: this.selectedTeacherCode,
        schedulingSource: this.schedulingSource,
        sectionIds: this.sectionIds,
        sectionTimes: this.sectionTimes,
        courseScheduleIds: this.courseScheduleIds,
      };
      this.$router.push({
        path: '/workoutSchedule/courseTeacherSheet',
        query: {
          classItem: JSON.stringify(classItem),
          subject: this.classDetail[0].course.subject,
        },
      });
    },
    // 课程回放
    async playCourse(record) {
      try {
        const { data } = await getReplayLink(record.uuid);
        const videoInfo = data?.[0];
        if (videoInfo?.type === 'HTML_URL') {
          window.open(videoInfo?.link);
          return;
        }
        this.$router.push({ path: '/workoutSchedule/lessonReplay', query: { courseScheduleId: record.uuid } });
      } catch {
        this.$router.push({ path: '/workoutSchedule/lessonReplay', query: { courseScheduleId: record.uuid } });
      }
    },
    // 监课
    playClass(record) {
      console.log(record);
      // 上课平台是classIn，直接打开
      if (record.platform === 'CLASS_IN') {
        window.open(record.liveUrl, '_blank');
      } else if (record.platform === 'TALK_CLOUD') {
        this.queryPlayCourse(record.uuid);
      }
    },
    queryPlayCourse(courseScheduleId) {
      const params = {
        courseScheduleId,
      };
      queryPlayCourse(params).then((res) => {
        window.open(res.data.content.inspectorLink, '_blank');
      });
    },
    cancelEndClass() {
      this.isEndClassVisible = false;
    },
    selectStudentInfo(val, option) {
      const { data } = option;
      this.addStudentName = data.attrs.data.name;
      this.addStudentCode = data.attrs.data.code;
    },
    disabledDate(current) {
      const time = this.$moment().endOf('day').subtract(1, 'days');
      return current && current < time;
    },
    toStudentDetail(record) {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: record.student.uuid },
      });
      window.open(routeUrl.href, '_blank');
    },
    changeSubstitute(e) {
      this.showSubstitute = e.target.value;
    },
    handleSearchTimezone(selectedKeys, confirm, dataIndex) {
      confirm();
      this.pagination.current = 1;
      this.renderScheduleListTab();
    },
    handleTimezone(clearFilters) {
      clearFilters();
      this.scheduleForm.convertTimezone = undefined;
      this.pagination.current = 1;
      this.renderScheduleListTab();
    },
    handleSearchData() {
      this.pagination.current = 1;
      this.renderScheduleListTab();
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    async showAdjustWeekFun() {
      this.adjustWeekloading = true;

      try {
        const result = await autoCancelScheduleOrderModal({
          courseScheduleIds: this.hasSelected ? this.selectedIds : undefined,
          formClassId: this.classId,
        });
        if (!result) return;

        this.showAdjustWeek = true;

        if (this.hasSelected) {
          const data = this.selectedRows.reduce((p, c) => {
            const { dayOfWeek, startDateTime } = c;
            const time = this.$moment(startDateTime).format('HH:mm');
            p[dayOfWeek] ||= time;
            return p;
          }, {});

          this.adjustWeekObj = data;
          return;
        }

        // 查询课节的星期
        postReq('/api/admin/scheduling/course_schedule/week', { formClassId: this.classId })
          .then((res) => {
            this.adjustWeekObj = res.data.content;
          })
          .catch((err) => {
            if (!err.response.data.data) return;
            this.error = err.response.data;
            this.isVisible = true;
          });
      } finally {
        this.adjustWeekloading = false;
      }
    },

    onCloseWeek() {
      this.showAdjustWeek = false;
      this.weekForm.resetFields();
    },
    adjustWeekFun() {
      this.weekForm.validateFields((err, values) => {
        if (!err) {
          const weeks = [];
          for (const key in this.adjustWeekObj) {
            if (values[`week${key}`] || values[`time${key}`]) {
              if (!values[`week${key}`]) {
                this.$message.warning('请选择新课节星期');
                return;
              }
              if (!values[`time${key}`]) {
                this.$message.warning('请选择新课节时分');
                return;
              }
              weeks.push({
                fromDay: key,
                toDay: values[`week${key}`],
                toTime: values[`time${key}`].format('HH:mm'),
              });
            }
          }
          if (weeks.length > 0) {
            this.iconLoading = true;
            const params = {
              weeks,
              formClassId: this.classId,
            };
            if (this.hasSelected) {
              params.courseScheduleIds = this.selectedRowKeys;
            }

            this.iconLoading = true;
            // 校验是否有24小时开课
            checkAdjustWeekSchedule(params)
              .then((res) => {
                const { data } = res;
                if (data.exist) {
                  this.isWeekVisible = true;
                  this.iconLoading = false;
                  this.sectionIds = data.check24HourResults.sectionIds;
                  this.sectionTimes = data.check24HourResults.sectionTimes;
                  this.selectedTeacherName = data.check24HourResults.teacherName;
                  this.selectedTeacherId = data.check24HourResults.teacherId;
                  this.selectedTeacherCode = data.check24HourResults.teacherCode;
                  this.courseScheduleIds = data.check24HourResults.courseScheduleIds;
                } else {
                  postReq(`/api/admin/scheduling/course_schedule/adjust_week/batch`, params)
                    .then((res) => {
                      this.iconLoading = false;
                      this.onCloseWeek();
                      this.renderScheduleListTab();
                      this.inintSelected();
                    })
                    .catch((error) => {
                      this.iconLoading = false;
                    });
                }
              })
              .catch((error) => {
                this.iconLoading = false;
              });
          } else {
            this.$message.warning('未作任何修改');
          }
        }
      });
    },
    adjustTimeFun() {
      // 校验可授级别

      this.adjustForm.validateFields((err, values) => {
        if (!err) {
          this.iconLoading = true;
          // 校验目标时间是否在24小时内
          const params = {
            courseScheduleId: this.tableListItem.uuid,
            date: values.date.format('YYYY-MM-DD HH:mm'),
            formClassId: this.classId,
          };
          checkAdjustDateSchedule(params)
            .then((res) => {
              console.log(res);
              const { data } = res;
              // 为true，才弹弹窗把!去掉
              if (data.exist) {
                this.isBillVisible = true;
                this.iconLoading = false;
                this.sectionIds = data.checkIn24HourRespDto.sectionIds;
                this.sectionTimes = data.checkIn24HourRespDto.sectionTimes;
                this.selectedTeacherName = data.checkIn24HourRespDto.teacherName;
                this.selectedTeacherId = data.checkIn24HourRespDto.teacherId;
                this.selectedTeacherCode = data.checkIn24HourRespDto.teacherCode;
                this.courseScheduleIds = data.checkIn24HourRespDto.courseScheduleIds;
              } else {
                // 直接调整课节时间
                const params = {
                  date: values.date.format('YYYY-MM-DD HH:mm'),
                  courseScheduleId: this.tableListItem.uuid,
                  formClassId: this.classId,
                };
                postReq(`/api/admin/scheduling/course_schedule/adjust_date`, params, { showErrorType: 'modal' })
                  .then((res) => {
                    this.iconLoading = false;
                    this.onCloseAdjust();
                    this.renderScheduleListTab();
                  })
                  .catch((err) => {
                    console.log('err', err);
                    this.iconLoading = false;
                    if (!err.response?.data?.data) return;
                    this.error = err.response.data;
                    this.isVisible = true;
                  });
              }
            })
            .catch(() => {
              this.iconLoading = false;
            });
        }
      });
    },
    onCloseAdjust() {
      this.showAdjustFlag = false;
      this.adjustForm.resetFields();
    },
    async adjustTime(record) {
      this.adjustTimeloading = true;

      try {
        const result = await autoCancelScheduleOrderModal({
          courseScheduleIds: [record.uuid],
          formClassId: this.classId,
        });

        if (!result) return;

        this.showAdjustFlag = true;
        this.schedulingSource = 'CHANGE_TIME';
        this.tableListItem = record;
      } finally {
        this.adjustTimeloading = false;
      }
    },
    handleOk() {
      this.isVisible = false;
    },
    onCloseTeacher() {
      this.isCheckClassVisible = false;
      this.batchUpdateTeacherVisible = false;
      this.teacherForm.resetFields();
      this.batchTeacher = false;
      this.showSubstitute = true;
    },
    onChangeTeacher(e) {
      // this.batchTeacher = e.target.checked;
    },
    searchAssignedTeacher(e) {
      postReq('/api/admin/teacher/search/1', { fullName: e, newScheduleEnable: true }).then((res) => {
        this.assignedTeacherOptions = res.data.content;
      });
    },
    toClassIn(classinId) {
      window.open(
        `https://console.eeo.cn/saas/school/index.html#/SinglePage/CourseManagement/CourseLessonManagement?courseId=${classinId}`,
        '_blank',
      );
    },
    // 获取助教老师
    queryAssistantOptions(name) {
      postReq('/api/account/ref/teaching_assistant', { name }).then((res) => {
        this.assistantOptions = res.data.content;
      });
    },
    // 页码切换
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.renderTab(); // 页码切换，刷新tab数据
    },
    // Tab切换
    onTabChange() {
      this.resetPaging();
      this.renderTab();
    },
    // render学生/排课列表
    renderTab() {
      if (this.activeKey == 2) {
        // 学生tab
        this.renderStudentListTab();
      } else {
        // 默认activeKey为1: 排课tab
        this.renderScheduleListTab();
      }
    },
    // 学生列表
    renderStudentListTab() {
      this.loading = true;
      postReq(`${this.searchStudentUrl}${this.pagination.current}`, this.studentForm)
        .then((res) => {
          this.setPaging(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 排课列表
    renderScheduleListTab() {
      if (this.$route.query.courseScheduleIds) {
        this.scheduleForm.courseScheduleIds = this.$route.query.courseScheduleIds;
      }
      this.loading = true;
      postReq(`${this.searchUrl}${this.pagination.current}`, this.scheduleForm)
        .then((res) => {
          this.setPaging(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPaging(res) {
      this.pagination.content = res.data.content;
      this.pagination.pageSize = res.data.pageSize;
      this.pagination.total = res.data.totalCount;
      this.pagination.current = res.data.currentPage;
      console.log(res);
    },
    resetPaging() {
      Object.assign(this.pagination, {
        pageSize: 20,
        current: 1,
        total: 0,
        content: [],
      });
    },
    getClassDetail() {
      getReq(`/api/admin/scheduling/form_class/${this.classId}`).then((res) => {
        this.classDetail = [].concat(res.data.content);

        this.courseId = res.data.content.courseId;
      });
    },
    toSchedule() {
      const teachingLanguage = this.classDetail[0].data.teachingLanguage;

      this.$router.push({
        path: '/workoutSchedule/courseSchedule',
        query: { classId: this.classId, courseId: this.courseId, teachingLanguage },
      });
    },
    showUpdateScheduleModal(txt, item) {
      console.log(item);
      this.updateScheduleVisible = true;
      this.curScheduleId = item.uuid;
      this.updateScheduleForm.scheduleName = item.scheduleName;
      this.updateScheduleForm.assistant = item.assistant;
      if (item.assistant) {
        this.queryAssistantOptions(item.data.assistantName);
      }
      this.updateScheduleForm.seatNum = item.data.seatNum;
    },
    updateSchedule() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.updateScheduleVisible = false;
          this.loading = true;
          putReq(`/api/admin/scheduling/${this.curScheduleId}`, { ...this.updateScheduleForm })
            .then((res) => {
              this.renderScheduleListTab();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    async showAdjustSectionModal(txt, item) {
      this.adjustSectionloading = true;
      try {
        const result = await autoCancelScheduleOrderModal({
          courseScheduleIds: [item.uuid],
          formClassId: this.classId,
        });

        if (!result) return;

        this.adjustSectionVisible = true;
        this.curScheduleId = item.uuid;
        this.adjustSectionForm.sectionId = item.courseSectionId;
        this.adjustSectionForm.sectionName = item.courseSectionRespDTO.sectionName;
        postReq('/api/admin/scheduling/course/section/search', {
          courseId: this.courseId,
          formClassId: this.classId,
        }).then((res) => {
          this.sectionOptions = res.data.content.filter((item) => item.sectionNeedShow);
        });
      } finally {
        this.adjustSectionloading = false;
      }
    },
    adjustSection() {
      this.loading = true;
      const params = {
        oldCourseScheduleId: this.curScheduleId, // 选中的课节ID
        batch: this.adjustSectionForm.isBatchAdjust,
        newCourseSectionId: this.adjustSectionForm.sectionId, // 选中的级别I'D
      };
      // 判断有无课节级别
      postReq('/api/teacher/work_flow/adjust_section/ability', params).then((res) => {
        const { data } = res;
        // 新老师有不可授级别
        if (data.canTeach === false) {
          this.structuresList = data.structures;
          this.isCheckClassVisible = true;
          this.continueClassType = 'section';
          this.loading = false;
        } else {
          this.confirmAdjustSection();
        }
      });
    },
    confirmAdjustSection() {
      postReq(
        `/api/admin/scheduling/course_schedule/adjust/${this.curScheduleId}/${this.adjustSectionForm.sectionId}/${this.adjustSectionForm.isBatchAdjust}`,
        {},
      )
        .then((res) => {
          this.adjustSectionVisible = false;
          this.isCheckClassVisible = false;
          this.renderScheduleListTab();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async showCancelScheduleModal(txt, item) {
      try {
        this.cancelLoading = true;

        // 从之前逻辑来看没有item则是批量取消
        let courseScheduleIds = [];
        if (!item) {
          courseScheduleIds = this.selectedIds;
          this.realignFirstSchedule = this.selectedRows?.[0];
        } else {
          courseScheduleIds = [item.uuid];
          this.realignFirstSchedule = item;
        }

        const result = await autoCancelScheduleOrderModal({
          courseScheduleIds,
          formClassId: this.classId,
        });
        if (!result) return;

        // 清空
        this.scheduleCancelReason = null;
        this.otherCancelReason = '';
        this.cancelScheduleName = '';

        this.cancelScheduleVisible = true;
        this.cancelScheduleName = (item && item.scheduleName) || `${this.selectedRowKeys.length}个课节`;
        if (item) {
          this.selectedIds = [].concat(item.uuid);
        }
      } finally {
        this.cancelLoading = false;
      }
    },
    cancelSchedule() {
      if (!this.scheduleCancelReason) return;
      if (this.scheduleCancelReason === 'OTHER' && !this.otherCancelReason.trim()) return;

      this.cancelScheduleVisible = false;
      this.loading = true;
      postReq('/api/admin/scheduling/cancel', {
        reschedule: this.realignChecked,
        courseScheduleList: this.selectedIds,
        scheduleCancelReason: this.scheduleCancelReason,
        data: {
          otherCancelReason: this.scheduleCancelReason === 'OTHER' ? this.otherCancelReason : '',
        },
      })
        .then((res) => {
          this.renderScheduleListTab();
          this.selectedRowKeys.length = 0;
          this.selectedRowKeys = [];
          this.selectedIds = [];
          this.selectedRows = [];
          this.realignChecked = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    inintSelected() {
      this.selectedRowKeys = [];
      this.selectedIds = [];
      this.selectedRows = [];
    },
    onSelectChange(selectedRowKeys) {
      this.$nextTick(() => {
        this.selectedRowKeys = selectedRowKeys;
      });
    },
    onSelect(record, selected) {
      selected
        ? this.selectedRows.push(record)
        : this.selectedRows.splice(
            this.selectedRows.findIndex((x) => x.uuid === record.uuid),
            1,
          );
      const selectedIds = [];
      for (let i = 0; i < this.selectedRows.length; i++) {
        selectedIds.push(this.selectedRows[i].uuid);
      }
      this.selectedIds = selectedIds;

      console.log(this.selectedRows);
    },
    onSelectAll(selected, selectedRows, changeRows) {
      console.log(changeRows);
      this.selectedRows = selected
        ? this.selectedRows.concat(changeRows)
        : this.selectedRows.filter((x) => !changeRows.find((i) => i.uuid === x.uuid));
      const selectedIds = [];
      for (let i = 0; i < this.selectedRows.length; i++) {
        selectedIds.push(this.selectedRows[i].uuid);
      }
      this.selectedIds = selectedIds;
    },
    // onSelectChange(selectedRowKeys, selectRows) {
    //   console.log(selectRows)

    //   this.selectedRowKeys = selectedRowKeys;
    //    let selectedIds = [];
    //   for (let i = 0; i < selectRows.length; i++) {
    //     selectedIds.push(selectRows[i].uuid);
    //   }
    //    this.selectedIds = selectedIds;
    //  console.log(this.selectedIds)
    // },
    // 回放
    async toReplay(url) {
      window.open(url, '_blank');
    },
    cancelUpdateScheduleModal() {
      this.batchUpdateScheduleVisible = false;
      this.showSubstitute = true;
    },
    showBatchUpdateScheduleModal() {
      this.batchUpdateScheduleVisible = true;
      this.batchAssistantId = null;
    },
    async showBatchUpdateTeacherModal() {
      this.batchUpdateTeacherLoading = true;

      try {
        const result = await autoCancelScheduleOrderModal({
          courseScheduleIds: this.selectedIds,
          formClassId: this.classId,
        });
        if (!result) return;

        this.batchUpdateTeacherVisible = true;
        this.schedulingSource = 'CHANGE_TEACHER';
        this.$nextTick(() => {
          this.teacherForm.setFieldsValue({
            substitute: true,
          });
        });
      } finally {
        this.batchUpdateTeacherLoading = false;
      }
    },
    // 批量分配助教
    batchUpdateSchedule() {
      if (!this.batchAssistantId) {
        return;
      }
      this.batchUpdateScheduleVisible = false;
      this.loading = true;
      postReq(`/api/admin/scheduling/course_schedule/batch_assistant/${this.batchAssistantId}`, this.selectedIds)
        .then((res) => {
          this.renderScheduleListTab();
          this.selectedRowKeys.length = 0;
          this.selectedRowKeys = [];
          this.selectedIds = [];
          this.selectedRows = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    continueClass() {
      console.log(this.continueClassType);
      // 点击继续排课，修改授课老师
      if (this.continueClassType === 'teacher') {
        // 校验24小时，有就发单，没有就修改授课老师
        this.checkCourseSchedule();
      } else if (this.continueClassType === 'section') {
        this.confirmAdjustSection();
      }
    },
    confirmTeacherFun() {
      // 修改授课老师
      this.teacherForm.validateFields((err, values) => {
        if (!err) {
          this.continueClassLoading = true;
          this.batchUpdateScheduleVisible = false;
          const params = {
            courseScheduleIds: this.selectedIds,
            teacherId: JSON.parse(values.batchTeacherId).uuid,
            formClassId: this.classId,
            // batch: this.batchTeacher,
            substitute: values.substitute,
            remark: values.remark,
            reason: values.reason,
          };
          postReq(`/api/admin/scheduling/change/teacher`, params)
            .then((res) => {
              this.renderScheduleListTab();
              this.selectedRowKeys.length = 0;
              this.selectedRowKeys = [];
              this.selectedIds = [];
              this.selectedRows = [];
              this.teacherLoading = false;
              this.onCloseTeacher();
            })
            .catch((err) => {
              if (!err.response.data.data) return;
              this.error = err.response.data;
              this.isVisible = true;
              this.teacherLoading = false;
            })
            .finally(() => {
              this.continueClassLoading = false;
              this.isCheckClassVisible = false;
              this.loading = false;
              this.teacherLoading = false;
            });
        }
      });
    },
    // 批量修改授课老师
    batchUpdateTeacher() {
      this.teacherForm.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          const teacher = JSON.parse(values.batchTeacherId);

          this.teacherLoading = true;
          this.selectedTeacherName = teacher.fullName;
          this.selectedTeacherId = teacher.uuid;
          this.selectedTeacherCode = teacher.code;
          // 校验老师有无可授级别
          this.checkTeacherClass(values);
        }
      });
    },
    checkCourseSchedule(values) {
      // 校验是否有24小时内课节
      const params = {
        formClassId: this.classId,
        courseScheduleIds: this.selectedIds,
      };
      checkCourseSchedule(params)
        .then((res) => {
          const { data } = res;
          // 为true，才弹弹窗把!去掉
          if (data.exist) {
            this.isBillVisible = true;
            this.sectionIds = data.sectionIds;
            this.sectionTimes = data.sectionTimes;
            this.courseScheduleIds = data.courseScheduleIds;
            this.teacherLoading = false;
          } else {
            // 走批量修改授课老师
            this.confirmTeacherFun();
          }
        })
        .catch(() => {
          this.teacherLoading = false;
        });
    },
    checkTeacherClass(values) {
      this.continueClassType = 'teacher';
      const paramsObj = {
        teacherId: JSON.parse(values.batchTeacherId).uuid,
        substitute: values.substitute,
        formClassId: this.classId,
        courseScheduleIds: this.selectedIds,
      };

      checkTeacherClass(paramsObj)
        .then((res) => {
          const { data } = res;
          // 新老师有不可授级别
          if (data.canTeach === false) {
            this.structuresList = data.structures;
            this.isCheckClassVisible = true;

            this.teacherLoading = false;
          } else {
            // 校验24小时开课课节，有就发单
            this.checkCourseSchedule(values);
          }
        })
        .catch(() => {
          this.teacherLoading = false;
        });
    },
    showShiftSectionModal() {
      this.shiftSectionVisible = true;
      this.newSectionTime = null;
      this.isBatchShift = false;
    },
    // 调整时间
    shiftSection() {
      this.shiftSectionVisible = false;
      this.loading = true;

      postReq(
        `/api/admin/scheduling/course_schedule/shift/${this.newSectionTime}/${this.isBatchShift}`,
        this.selectedIds,
      )
        .then((res) => {
          this.renderScheduleListTab();
          this.selectedRowKeys.length = 0;
          this.selectedRowKeys = [];
          this.selectedIds = [];
          this.selectedRows = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showAddStudentModal() {
      this.addStudentVisible = true;
      this.studentId = undefined;
      this.startDateTime = null;
    },
    addStudent() {
      this.addStudentVisible = false;
      this.loading = true;
      const data = {
        formClassId: this.classId,
        studentId: this.studentId,
        identity: 1,
        startDateTime: (!!this.startDateTime && this.startDateTime.format('YYYY-MM-DD 00:00')) || null,
      };
      postReq('/api/admin/scheduling/form_class/student', data, { showErrorType: 'modal' })
        .then((res) => {
          // 判断要不要给提示
          if (res.data?.content?.firstCourseSchedule) {
            this.isEndClassVisible = true;
            this.firstCourseSchedule = res.data.content.firstCourseSchedule;
          } else {
            this.isEndClassVisible = false;
          }
          this.renderStudentListTab();
          this.getClassDetail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchStudent(value) {
      postReq(`/api/admin/student/aggregate_search`, { keyword: value }).then((res) => {
        this.studentOptions = res.data;
      });
    },

    onEditClass(record) {
      this.$router.push({
        path: '/workoutSchedule/classEdit',
        query: { id: record.uuid, classAdminNameSub: record.data.headmasterName },
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
.class-wrap {
  width: 100%;
  min-height: calc(100vh - 64px);
  background-color: #fff;
  padding: 16px 20px;
}
.week-wrap {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin-top: 5px;
}
</style>
